import { type BreadcrumbProps, type LoaderData, LosseKruumels, useLoaderData } from '@ubo/losse-sjedel'

const POST_TYPE_CRUMB: { [key: string]: BreadcrumbProps } = {
  // productItem: { title: 'Producten', uri: '/producten/' },
  post: { title: 'Artikelen', uri: '/artikelen/' },
  project: { title: 'Projecten', uri: '/projecten/' },
  vacatures: { title: 'Vacatures', uri: '/vacatures/' },
  merk: { title: 'Merken', uri: '/merk/' }
}

export default function Breadcrumbs() {
  const { page } = useLoaderData<LoaderData>()
  const contentType = page?.contentType?.node?.name
  const crumbs: BreadcrumbProps[] = [{ title: 'Home', uri: '/' }]

  if (contentType && contentType in POST_TYPE_CRUMB) crumbs.push(POST_TYPE_CRUMB[contentType])

  return (
    <>
      {contentType === 'productItem' && (
        <LosseKruumels
          type="path"
          preCrumbs={crumbs}
          className="sm:block capitalize hidden children-ol:flex children-li:flex children-li:items-center children-ol:items-center text-white hover:children-a:underline children-span:line-clamp-1 children-span:font-semibold children-a:font-light children-a:first-letter:uppercase children-a:inline-block"
          seperator={<span className="mx-3 w-[3px] h-[15px] bg-site-accent relative"></span>}
        />
      )}

      {contentType !== 'productItem' && (
        <LosseKruumels
          type="fragment"
          preCrumbs={crumbs}
          className="sm:block hidden children-ol:flex children-li:flex children-li:items-center children-ol:items-center text-white hover:children-a:underline children-span:line-clamp-1 children-span:font-semibold children-a:font-light children-a:first-letter:uppercase children-a:inline-block"
          seperator={<span className="mx-3 w-[3px] h-[15px] bg-site-accent relative"></span>}
        />
      )}

      <nav className="sm:hidden block children-ol:flex children-li:flex children-li:items-center children-ol:items-center text-white hover:children-a:underline children-span:line-clamp-1 children-span:font-semibold children-a:font-light children-a:first-letter:uppercase children-a:inline-block">
        <button
          type="button"
          className="losse-kruumel-history"
          onClick={() => {
            if (window.history.length > 1) window.history.back()
            else window.location.href = '/'
          }}
        >
          <span className="mx-2 text-site-accent font-light text-lg relative">{`<`}</span>
          Terug
        </button>
      </nav>
    </>
  )
}
