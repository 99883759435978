import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { Page_Flexcontent_Flex_Posts, ProductItem, RootQueryToProductItemConnection } from '~/graphql/types'
import Product from './post-types/Product'
import clsx from 'clsx'

type ProductCategoryOverview = Page_Flexcontent_Flex_Posts & {
  subdata: {
    ProductCategories: RootQueryToProductItemConnection
  }
}

export default function ProductCategoryOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const data = fields as ProductCategoryOverview
  const theme = !fields.background ? 'light' : fields.background === '#E9EAEA' ? 'light' : 'dark'

  return (
    <section data-component="ProductCategoryOverview">
      <div className="bg-site-alt">
        <div className="container">
          <div className="flex flex-col gap-7 lg:gap-11 pb-10 sm:pb-14 lg:pb-24 pt-6 sm:pt-11">
            <Breadcrumbs />
            {fields?.title && (
              <h1 className="max-sm:flex max-sm:flex-col md:text-center py-2">
                {fields?.title?.split(' | ').map((part, key) => (
                  <span
                    key={key}
                    className={clsx(
                      'text-3xl xs:text-4xl sm:text-5xl uppercase md:text-center font-light lg:text-6xl mx-1 lg:mx-2 drop-shadow text-white',
                      key !== 0 ? 'font-semibold' : ''
                    )}
                  >
                    {part}
                  </span>
                ))}
              </h1>
            )}
          </div>
        </div>
      </div>
      <div className="bg-site-grey section section--with-bg">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 xm:gap-4 md:gap-6">
            {data?.subdata?.ProductCategories?.edges?.map(({ node }) => {
              const product = node as ProductItem
              return <Product darktext={fields.textcolor === 'black'} type={theme} key={product.databaseId} data={product} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
