import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Fragment } from 'react'
import { Arrow } from '~/components/elements/Arrow'
import Links from '~/components/elements/Links'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'

export default function ProductFurnishingOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const theme = !fields.background ? 'light' : fields.background === '#E9EAEA' ? 'light' : 'dark'

  return (
    <section data-component="ProductFurnishingOverview" className="section section--with-bg bg-site-grey">
      <div className="container">
        <Subtitle>{fields.subtitle}</Subtitle>
        <Title>{fields.title}</Title>
        <div className="mt-6 sm:mt-14">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
            {fields.items.map((item, i) => {
              const title = item.normallink ? item?.link?.title : item?.replacementtitle || item?.relationitem?.title

              return (
                <div
                  className="bg-site-alt overflow-hidden group hover:bg-site-base !transition-all !duration-200 rounded-[7px] shadow-site-shadow"
                  key={i}
                >
                  <LosseLink
                    to={item.normallink ? item?.link?.url : item?.relationitem?.uri}
                    className="flex flex-row sm:flex-col items-center sm:items-end h-full"
                  >
                    <div className="bg-site-alt group-hover:bg-site-base transition-all duration-200 relative rounded-t-[7px] w-full max-sm:w-[40%] h-[85px] sm:h-[130px] xl:h-[270px]">
                      {item?.relationitem?.featuredImage?.node && (
                        <LossePlaatjie
                          maxwidth={1000}
                          className="sm:absolute group-hover:opacity-50 transition-all z-10 duration-200 object-cover h-full w-full top-0 left-0"
                          src={item.relationitem.featuredImage.node}
                        />
                      )}
                    </div>
                    <div className="flex gap-3 w-full relative sm:-mt-5 z-20 px-4 sm:px-6 max-sm:pt-2 pb-3 sm:pb-5 items-center sm:items-end justify-between">
                      <span
                        className={clsx(
                          theme === 'light' && 'text-site-base sm:text-white sm:drop-shadow',
                          theme === 'dark' && 'text-white drop-shadow',
                          'text-base sm:text-lg  !leading-[1.1] font-light'
                        )}
                      >
                        {title.split('|').map((t, i) => (
                          <div
                            className={clsx(i === 0 ? 'text-2xl sm:text-3xl drop-shadow font-semibold sm:mb-3' : 'sm:block hidden')}
                            key={i}
                          >
                            {t
                              .trim()
                              .split(' ')
                              .map((t, i) => (
                                <Fragment key={i}>
                                  {t}
                                  <br />
                                </Fragment>
                              ))}
                          </div>
                        ))}
                      </span>
                      <Arrow size="medium" />
                    </div>
                  </LosseLink>
                </div>
              )
            })}
          </div>
          {fields.links && (
            <div className="flex mt-6 sm:mt-16 justify-center ">
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
